import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#f5f5f5',
    [Prop.HEADER]: '#3a3a3a',
    [Prop.CONTENT]: '#3a3a3a',
    [Prop.LINK_TXT]: '#004f71',
    [Prop.LINK_TXT_HOVER]: '#77c5d5',
    [Prop.BTN_BG]: '#ffffff',
    [Prop.BTN_TXT]: '#004f71',
    [Prop.BTN_BG_HOVER]: '#bbdde6',
    [Prop.BTN_TXT_HOVER]: '#004f71',
    [Prop.BTN_BG_ACTIVE]: '#bbdde6',
    [Prop.BTN_TXT_ACTIVE]: '#004f71',
    [Prop.BTN_BG_INACTIVE]: '#d8d8d8',
    [Prop.BTN_TXT_INACTIVE]: '#696969',
    [Prop.ELEMENT]: '#77c5d5',
    [Prop.ELEMENT_TXT]: '#004f71',
    [Prop.BORDER]: '#d8d8d8',
    [Prop.ICON]: '#77c5d5',
    [Prop.ICON_HOVER]: '#004f71',
    [Prop.ICON_2]: '#004f71',
    [Prop.ICON_HOVER_2]: '#004f71',
    [Prop.HEADER_BG]: '#000000',
    [Prop.HEADER_TXT]: '#ffffff',
    [Prop.HEADER_ICON]: '#77c5d5',
    [Prop.HEADER_BG_2]: '#77c5d5',
    [Prop.HEADER_TXT_2]: '#ffffff',
    [Prop.HEADER_ICON_2]: '#ffffff',
    [Prop.ODD_EVEN_OFFSET]: '#e5eded',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#004f71',
    [Prop.HEADER]: '#ffffff',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#bbdde6',
    [Prop.LINK_TXT_HOVER]: '#77c5d5',
    [Prop.BTN_BG]: '#ffffff',
    [Prop.BTN_TXT]: '#004f71',
    [Prop.BTN_BG_HOVER]: '#77c5d5',
    [Prop.BTN_TXT_HOVER]: '#004f71',
    [Prop.BTN_BG_ACTIVE]: '#77c5d5',
    [Prop.BTN_TXT_ACTIVE]: '#007396',
    [Prop.BTN_BG_INACTIVE]: '#d8d8d8',
    [Prop.BTN_TXT_INACTIVE]: '#696969',
    [Prop.ELEMENT]: '#f5f5f5',
    [Prop.ELEMENT_TXT]: '#004f71',
    [Prop.BORDER]: '#77c5d5',
    [Prop.ICON]: '#f5f5f5',
    [Prop.ICON_HOVER]: '#77c5d5',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#007396',
    [Prop.HEADER]: '#ffffff',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#bbdde6',
    [Prop.LINK_TXT_HOVER]: '#77c5d5',
    [Prop.BTN_BG]: '#bbdde6',
    [Prop.BTN_TXT]: '#007396',
    [Prop.BTN_BG_HOVER]: '#004f71',
    [Prop.BTN_TXT_HOVER]: '#bbdde6',
    [Prop.BTN_BG_ACTIVE]: '#004f71',
    [Prop.BTN_TXT_ACTIVE]: '#bbdde6',
    [Prop.BTN_BG_INACTIVE]: '#d8d8d8',
    [Prop.BTN_TXT_INACTIVE]: '#696969',
    [Prop.ELEMENT]: '#004f71',
    [Prop.ELEMENT_TXT]: '#bbdde6',
    [Prop.BORDER]: '#bbdde6',
    [Prop.ICON]: '#bbdde6',
    [Prop.ICON_HOVER]: '#004f71',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#77c5d5',
    [Prop.HEADER]: '#004f71',
    [Prop.CONTENT]: '#004f71',
    [Prop.LINK_TXT]: '#007396',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#007396',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#004f71',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#004f71',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#d8d8d8',
    [Prop.BTN_TXT_INACTIVE]: '#696969',
    [Prop.ELEMENT]: '#007396',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#bbdde6',
    [Prop.ICON]: '#007396',
    [Prop.ICON_HOVER]: '#004f71',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#bbdde6',
    [Prop.HEADER]: '#3a3a3a',
    [Prop.CONTENT]: '#3a3a3a',
    [Prop.LINK_TXT]: '#007396',
    [Prop.LINK_TXT_HOVER]: '#004f71',
    [Prop.BTN_BG]: '#77c5d5',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#004f71',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#004f71',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#d8d8d8',
    [Prop.BTN_TXT_INACTIVE]: '#696969',
    [Prop.ELEMENT]: '#007396',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#77c5d5',
    [Prop.ICON]: '#007396',
    [Prop.ICON_HOVER]: '#004f71',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#004f71',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#3A3A3A',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#3A3A3A',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#BBDDE6',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#BBDDE6',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#77c5d5',
    [Prop.LOGO_BG]: '#77c5d5',
    [Prop.CLOSE_BTN_BG]: '#007396',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#77c5d5',
    [Prop.MAIN_NAV_TXT]: '#004F71',
    [Prop.MAIN_NAV_BORDER]: '#bbdde6',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#77c5d5',
    [Prop.DRAWER_BTN_TXT]: '#004F71',
    [Prop.PROMO_BTN_BG]: '#004F71',
    [Prop.PROMO_BTN_TXT]: '#bbdde6',
    [Prop.SUB_NAV_BG]: '#007396',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#bbdde6',
  },

  [Components.NAVIGATION_TOGGLE]: {
    [Prop.NAV_TOGGLE_TXT]: '#007396',
    [Prop.NAV_TOGGLE_ICON]: '#007396',
    [Prop.NAV_TOGGLE_TXT_SCROLLED]: '#007396',
    [Prop.NAV_TOGGLE_ICON_SCROLLED]: '#007396',
  },

  [Components.DOR_TOGGLE]: {
    [Prop.BACKGROUND]: '#007396',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
  },

  [Components.SIDEBAR]: {
    [Prop.BACKGROUND]: '#007396',
    [Prop.CONTENT]: '#FFFFFF',
  },
};
